

<div class="about-us-content">
    <h1 class="title">{{i18n.getTextAbout('title')}}</h1>
    <div   *ngFor="let about of i18n.getTextAbout('content'); index as i; let even = even" class="row justify-content-around" >

        <div [id]="about.slug" [class]="even ? 'where-are-conetnt order-1' : 'mission-conetnt order-2'" 
            class="col-12 col-md-6 col-xl-5 ">
            <div class="img" [style]="'background-image: url(/assets/about/' + about.img + ');'">
            </div>
            <div class="text-content">
                <h1 class="title"> {{about.title}}</h1>
                <div class="subtitle" >{{about.subtitle}}</div>
            </div>
        </div>
        <div [class]="even ? 'where-are-conetnt order-2' : 'mission-conetnt order-1'"  class="col-12 col-md-6 col-xl-5 ">
            <div class="description" [innerHTML]="about.description" ></div>
            <img src="assets/about/quem_somos-3.png">
        </div>
    </div>

</div>

<!-- 

<div class="about-us-content">

    <h1 class="title">{{i18n.getTextAbout('title')}}</h1>
    
    <div class="row justify-content-around">

        <div class="where-are-conetnt  col-12 col-md-6 col-xl-5 ">
            <div class="img" [style]="'background-image: url(/assets/about/'+i18n.getTextAbout('whereAre').img+');'">
            </div>
            <div class="text-content">
                <h1 class="title"> {{i18n.getTextAbout('whereAre').title}}</h1>
                <div class="subtitle" >{{i18n.getTextAbout('whereAre').subtitle}}</div>
            </div>
        </div>
        <div class=" where-are-conetnt  col-12 col-md-6 col-xl-5 ">
            <div class="description" [innerHTML]="i18n.getTextAbout('whereAre').description" ></div>
            <img src="assets/about/quem_somos-3.png">
        </div>
    </div>
    <div class="row justify-content-around">
        <div class=" mission-conetnt  col-12 col-md-6 col-xl-5">
            <div class="description" [innerHTML]="i18n.getTextAbout('whereAre').description" ></div>
            <img src="assets/about/quem_somos-3.png">
        </div>
        <div class="mission-conetnt  col-12 col-md-6 col-xl-5">
            <div class="img" [style]="'background-image: url(/assets/about/'+i18n.getTextAbout('whereAre').img+');'">
            </div>
            <div class="text-content">
                <h1 class="title"> {{i18n.getTextAbout('whereAre').title}}</h1>
                <div class="subtitle" >{{i18n.getTextAbout('whereAre').subtitle}}</div>
            </div>
        </div>
    </div>

    <div class="row justify-content-around">

        <div class="where-are-conetnt  col-12 col-md-6  col-xl-5">
            <div class="img" [style]="'background-image: url(/assets/about/'+i18n.getTextAbout('whereAre').img+');'">
            </div>
            <div class="text-content">
                <h1 class="title"> {{i18n.getTextAbout('whereAre').title}}</h1>
                <div class="subtitle" >{{i18n.getTextAbout('whereAre').subtitle}}</div>
            </div>
        </div>
        <div class=" where-are-conetnt  col-12 col-md-6 col-xl-5">
            <div class="description" [innerHTML]="i18n.getTextAbout('whereAre').description" ></div>
            <img src="assets/about/quem_somos-3.png">
        </div>
    </div>

        


</div> -->
