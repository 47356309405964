import { Component, OnInit } from '@angular/core';
import {I18nService} from "../../services/translations/i18n.service";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
public about : any; 
  constructor(public i18n: I18nService ) {

   

  }

  ngOnInit(): void {
  }

}
