<div  *ngIf="selectedProjectSlug !==null">
  <app-home-screen-splash [sliders]="sliders" [isService]="true" ></app-home-screen-splash>
  <app-home-second-block  [contentInformation]="project.information" [isService]="true"></app-home-second-block>
  <div class="project-content">
  <div class="row project-row ">
    <ng-container *ngFor="let projectItem of i18n.getProjects(); index as i">
    <div class="col-12 col-sm-12 col-md-6 col-xl-4 project-card"
      *ngIf="project.slug !== projectItem.slug"
    >
      <app-home-project-card
        [project]="projectItem"
        [isHomePage]="false"
      ></app-home-project-card>

    </div>
    </ng-container>
  </div>
  </div>



</div>
<div *ngIf="selectedProjectSlug ===null">
  <div style="background-color:white ; margin-top: 200px;" >
    <app-home-projects-block></app-home-projects-block>
  </div>
</div>


