import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {Project} from "../../classes/project";
import { I18nService } from '../../services/translations/i18n.service';
import { Silder } from 'src/app/classes/silder';

@Component({
  selector: 'app-projects',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {
  private subscribeLang = null;

  public project: Project;
  public selectedProjectSlug = null;

  public sliders: Array<Silder> = null;

  constructor(
          private route: ActivatedRoute,
          public i18n: I18nService
        )
  {



    this.route.paramMap.subscribe(params => {
      this.selectedProjectSlug = params.get("id");
      this.project = i18n.getProjectBySlug(this.selectedProjectSlug);
      this.sliders = this.project.imagens;
console.log("mudei de rota Service.component ");

    });

    this.subscribeLang = this.i18n.checkChangeLanguage().subscribe(data =>{
      this.project = this.i18n.getProjectBySlug(this.selectedProjectSlug);
      this.sliders = this.project.imagens;

      console.log('i18n checkChangeLanguage  subscribe !!!');
    });




  }


  ngOnInit(): void {

  }

  ngOnDestroy (): void {
    this.subscribeLang.unsubscribe();
  }
}
