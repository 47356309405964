<nav id="navbar" [ngClass]="openMenu ? 'active' : ''">
    <div class="icon_menu_ToOpen" [ngClass]="openMenu ? 'active' : ''" (click)="toggleMenuClick()">
        <span class="m_1"></span>
        <span class="m_2"></span>
    </div>

    <div class="div_logo_menu">
      <a routerLink="/" (click)="openMenu = false;" routerLinkActive="active" >
        <img class="logo_menu" [src]="srcLogoImage" />
      </a>

    </div>

    <div class="social-media d-none d-lg-block">
      <a *ngIf="i18n.getSocialMedia('facebook').length" target="_blank" [href]="i18n.getSocialMedia('facebook')"><i class="fa fa-facebook-f" aria-hidden="true"></i></a>
      <a *ngIf="i18n.getSocialMedia('linkedin').length" target="_blank" [href]="i18n.getSocialMedia('linkedin')"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
      <a *ngIf="i18n.getSocialMedia('youtube').length"  target="_blank" [href]="i18n.getSocialMedia('youtube')"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
    </div>
</nav>

<div class="nav-menu-open" [ngClass]="openMenu ? 'active' : 'd-none'">
    <div class="icon_menu_ToOpen" [ngClass]="openMenu ? 'active' : ''" (click)="toggleMenuClick()">
        <span class="m_1"></span>
        <span class="m_2"></span>
    </div>
    <ul *ngIf="openMenu">
        <li>
            <span>
        <a routerLink="/" (click)="openMenu = false;" routerLinkActive="active" >{{
          i18n.getTextHeader("home-page")
          }}
        </a>
      </span>
        </li>
        <li>
            <span>
        <a routerLink="/about-us" (click)="openMenu = false;" routerLinkActive="active">{{
      i18n.getTextHeader("about-us")
    }}</a>
      </span>
        </li>
        <li>
            <span>
        <a routerLink="/services" (click)="openMenu = false;"routerLinkActive="active">{{
  i18n.getTextHeader("services")
}}</a>
      </span>
        </li>
        <li>
            <span>
        <a routerLink="/" routerLinkActive="active" (click)="openMenu = false;" fragment="home-contacts">{{
  i18n.getTextHeader("contacts")
}}</a>
      </span>
        </li>
    </ul>

    <div *ngIf="i18n.getTextSettings('activeEnLangues')"  class="languages">
        <button (click)="i18n.setLanguage('pt'); openMenu = false;">
      {{ i18n.getTextHeader("portuguese") }}
    </button>
    <button (click)="i18n.setLanguage('en') ; openMenu = false;">
      {{ i18n.getTextHeader("english") }}
    </button>
        <div class="social-media_1 d-lg-none">
          <a *ngIf="i18n.getSocialMedia('facebook').length" target="_blank" [href]="i18n.getSocialMedia('facebook')"><i class="fa fa-facebook-f" aria-hidden="true"></i></a>
          <a *ngIf="i18n.getSocialMedia('linkedin').length" target="_blank" [href]="i18n.getSocialMedia('linkedin')"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
          <a *ngIf="i18n.getSocialMedia('youtube').length"  target="_blank" [href]="i18n.getSocialMedia('youtube')"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
        </div>
    </div>
</div>
