import { Component, OnInit, Input } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';

@Component({
  selector: 'app-home-second-block',
  templateUrl: './home-second-block.component.html',
  styleUrls: ['./home-second-block.component.scss'],
})
export class HomeSecondBlockComponent implements OnInit {
  @Input() contentInformation: any ;
  @Input() isService: any;
  constructor(public i18n: I18nService) {

    if(location.pathname === "/privacy-cookie"){

    }

  }


  ngOnInit(): void {


  }
}
