import { Injectable } from '@angular/core';
import { Project } from '../../classes/project';
import {  Silder } from '../../classes/silder';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private language = 'pt';
  private observable = new Subject<any>();
  //public subscribeLang = null;

  constructor() {
    let languageLocalStorage = window.localStorage.getItem('selectedLanguage');
    this.language = languageLocalStorage ? languageLocalStorage : 'pt';
    window.localStorage.setItem('selectedLanguage', this.language);
  }

  setLanguage(_newLanguage) {
    this.language = _newLanguage;
    window.localStorage.setItem('selectedLanguage', this.language);
    this.observable.next(this.language);
  }

  getLanguage() {
    return this.language;
  }

  checkChangeLanguage() {
    return this.observable;
  }

  /*
   *
   * Get All text for footer from I18n lib
   *
   * */
  getTextHeader(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Header;
    if (data[language][key] !== undefined) {
      return data[language][key];
    }
  }

  /*
   *
   * Get All text for About from I18n lib
   *
   * */
  getTextAbout(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].About;
    if (data[language][key] !== undefined) {
      return data[language][key];
    }
  }

  /*
   *
   * Get All text for footer from I18n lib
   *
   * */
  getTextFooter(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Footer;
    if (data[language][key] !== undefined) {
      return data[language][key];
    }
  }

  /*
   *
   * Get All text for Contacts from I18n lib
   *
   * */
  getTextContacts(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Contacts;
    if (data[key] !== undefined) {
      return data[key];
    }
  }
  /*
   *
   * Get All text for Geral from I18n lib
   *
   * */
  getTextSettings(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Settings;
    if (data[key] !== undefined) {
      return data[key];
    }
  }


  /*
   *
   * Get All text for Settings from I18n lib
   *
   * */
  getTextGeral(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Geral;
    if (data[language][key] !== undefined) {
      return data[language][key];
    }
  }

  /*
   *
   * Get All text for Block2 from I18n lib
   *
   * */
  getTextBlock2(): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].Block2;

    //console.log(data[language] )
    if (data[language] !== undefined) {

      return data[language];
    }
  }

  /*
   *
   * Get All text for settings from I18n lib
   *
   * */
  getSocialMedia(key: any): string {
    const language = this.language.toUpperCase();
    const data = window['i18n'].SocialMedia;
    if (data[key] !== undefined) {
      return data[key];
    }
  }

  /*
   *
   * Get All Projects from I18n lib
   *
   * */
  getProjects(): Array<Project> {
    const language = this.language.toUpperCase();
    const dataI18n = window['i18n'].Projects;
    let projects = new Array<Project>();

    for (let itemProject in dataI18n) {
      let project = new Project();
      project.slug = itemProject;
      project.title = dataI18n[itemProject][language].title;
      project.imagemDestaque = dataI18n[itemProject][language].imagemDestaque;
      project.imagens = dataI18n[itemProject][language].imagens;
      project.information= dataI18n[itemProject][language]['information'];
      projects.push(project);
    }
    return projects;
  }
  getOneProjects( projectsName ): any {
    const language = this.language.toUpperCase();
    const dataI18n = window['i18n'].Projects;
      let project = new Project();
      project.slug = projectsName;
      project.title = dataI18n[projectsName][language].title;
      project.imagemDestaque = dataI18n[projectsName][language].imagemDestaque;
      project.imagens = dataI18n[projectsName][language].imagens;
      project.information= dataI18n[projectsName][language]['information'];
    return project;
  }


  /*
   *
   * Get All text for Policy from I18n lib
   *
   * */

  getTextPolicy(key: any) {
    const language = this.language.toUpperCase();
    const dataI18n = window['i18n'].Policy;

    return dataI18n[key][language]






  }
  /*
   *
   * Get All text for Carousel from I18n lib
   *
   * */

  getCarousel(): Array<Silder> {
    const language = this.language.toUpperCase();
    const dataI18n = window['i18n'].Carousel;
    let carousel = new Array<Silder>();

    for (let itemCarousel in dataI18n) {
      let silder = new Silder();
      silder.slug = itemCarousel;
      silder.title = dataI18n[itemCarousel][language].title;
      silder.subtitle = dataI18n[itemCarousel][language].subtitle;
      silder.link = dataI18n[itemCarousel][language].link;


      silder.imagemDestaque = dataI18n[itemCarousel].imagemDestaque;
      //console.log(silder);
      carousel.push(silder);
    }

    return carousel;
  }

  /*
   *
   * Get One Project By Slug from I18n lib
   * return null if not find
   * return a class Project
   *
   * */
  getProjectBySlug(_slug): Project {
    const language = this.language.toUpperCase();
    const dataI18n = window['i18n'].Projects;

    for (let itemProject in dataI18n) {
      if (itemProject === _slug) {
        let project = new Project();
        project.slug = itemProject;
        project.title = dataI18n[itemProject][language].title;
        project.imagemDestaque = dataI18n[itemProject][language].imagemDestaque;
        project.imagens = dataI18n[itemProject][language].imagens;
        project.information = dataI18n[itemProject][language].information;
        return project;
      }
    }

    return null;
  }
}
