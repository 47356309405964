<div class="project-content">
  <div class="row">
    <div
      class="col-12 col-sm-12 col-md-6 project-card"
      *ngFor="let project of i18n.getProjects(); index as i"
    >
      <app-home-project-card
        [project]="project"
        [isHomePage]="true"
      ></app-home-project-card>
    </div>
  </div>
</div>
