import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ServicesComponent } from './components/services/services.component';
import { HomeContactsComponent } from './components/home-contacts/home-contacts.component';
import { HomeScreenSplashComponent } from './components/home-screen-splash/home-screen-splash.component';
import { HomeSecondBlockComponent } from './components/home-second-block/home-second-block.component';
import { HomeProjectsBlockComponent } from './components/home-projects-block/home-projects-block.component';
import { HomeProjectCardComponent } from './components/home-project-card/home-project-card.component';
import { PrivacyCookieComponent } from './components/privacy-cookie/privacy-cookie.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    AboutUsComponent,
    ServicesComponent,
    HomeContactsComponent,
    HomeScreenSplashComponent,
    HomeSecondBlockComponent,
    HomeProjectsBlockComponent,
    HomeProjectCardComponent,
    PrivacyCookieComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
