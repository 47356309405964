<div id="footer" class="footer-content footer-shadow-box">
  <div class="content row">
    <div class="logo-content col-xl-3">
      <a routerLink="/">
        <img class="img-fluid" src="/assets/wse_logo_footer.png"
      /></a>
    </div>
    <div class="row footer-menu col-xl-9">
      <ul class="d-none d-xl-block">
        <li class="title">
          <a routerLink="/">{{ i18n.getTextFooter("home-page") }}</a>
        </li>
      </ul>
      <ul class="d-none d-xl-block">
        <li class="title">{{ i18n.getTextFooter("contacts") }}</li>
        <li *ngIf="i18n.getSocialMedia('googlemaps').length">
          <a  [href]="i18n.getSocialMedia('googlemaps')" target="_blank">{{
            i18n.getTextFooter("contacts-loc")
          }}</a>
        </li>
        <li *ngIf="i18n.getSocialMedia('facebook').length">
          <a [href]="i18n.getSocialMedia('facebook')" target="_blank">{{
            i18n.getTextFooter("contacts-fb")
          }}</a>
        </li>
        <li *ngIf="i18n.getSocialMedia('linkedin').length">
          <a [href]="i18n.getSocialMedia('linkedin')" target="_blank">{{
            i18n.getTextFooter("contacts-in")
          }}</a>
        </li>
        <li *ngIf="i18n.getSocialMedia('youtube').length">
          <a [href]="i18n.getSocialMedia('youtube')" target="_blank">{{
            i18n.getTextFooter("contacts-yt")
          }}</a>
        </li>
      </ul>
      <ul class="d-none d-xl-block">
        <li class="title">{{ i18n.getTextFooter("about-us") }}</li>
        <li>
          <a routerLink="/about-us" fragment="about-us-who-we-are"
            >{{ i18n.getTextFooter("about-us-who-we-are") }}
          </a>
        </li>
        <li>
          <a routerLink="/about-us" fragment="about-us-vision">{{
            i18n.getTextFooter("about-us-vision")
          }}</a>
        </li>
        <li>
          <a routerLink="/about-us" fragment="about-us-mission">
            {{ i18n.getTextFooter("about-us-mission") }}</a
          >
        </li>
      </ul>
      <ul class="serive-footer-menu d-none d-xl-block">
        <li class="title">{{ i18n.getTextFooter("services") }}</li>
        <li *ngFor="let project of i18n.getProjects()">
          <a routerLink="/services/{{ project.slug }}"> {{ project.title }} </a>
        </li>
      </ul>
    </div>
    <ul class="polity-content">
      <li class="footer-year">@{{ anoCivil }} WSE</li>
      <li>
        <a routerLink="/privacy-policy">{{
          i18n.getTextFooter("privacy-policy")
        }}</a>
      </li>
      <li> <a routerLink="/privacy-cookie">{{ i18n.getTextFooter("cookie-policy") }} </a> </li>
      <li class="incograf-logo-content">
        <a href="https://www.incograf.com" target="_blank"
          ><img class="img-fluid" src="/assets/logo_incograf.png"
        /></a>
      </li>
    </ul>
  </div>
</div>
