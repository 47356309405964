import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';
import { Silder } from 'src/app/classes/silder';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private subscribeLang = null;
  public sliders: Array<Silder> = null;
  public  information  :any;
  constructor(public i18n: I18nService) {
    this.sliders = i18n.getCarousel();
    this.information = i18n.getTextBlock2();
  
    

    this.subscribeLang = this.i18n.checkChangeLanguage().subscribe((data) => {
      this.sliders = i18n.getCarousel();
      console.log('i18n checkChangeLanguage  subscribe !!!');
    });
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.subscribeLang.unsubscribe();
  }

}
