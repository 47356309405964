import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public anoCivil: number;

  constructor(public i18n: I18nService) {
    const dataActual = new Date();
    this.anoCivil = dataActual.getFullYear();
  }

  ngOnInit(): void {}
}
