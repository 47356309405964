import { Silder } from 'src/app/classes/silder';

export class Project {
  slug: string;
  title: string;
  imagemDestaque: string;
  imagens: Array<Silder>;
  information: any;
}

