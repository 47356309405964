<div  [class]="isService === true ? 'carousel-content-service' : 'carousel-content' ">
  <owl-carousel-o
    [options]="customOptions"
    (translated)="getPassedData($event)"
    #owlCar
  >
    <ng-container *ngFor="let slider of sliders; index as i">

      <ng-template carouselSlide [id]="slider.slug">
        <div class="slider" [style]="getBackgroundImageSlider(slider)"></div>
        <div class="slider-info " [style]="isService === true ? 'display : none' : ''">
          <p class="title d-none d-md-block" [innerHTML]="slider.title"></p>
          <p class="title d-block  d-md-none " [innerHTML]="removeHTml(slider.title)"></p>
          <p class="subtitle  d-none d-md-block" [innerHTML]="slider.subtitle">
          <p class="subtitle  d-block  d-md-none " [innerHTML]="removeHTml(slider.subtitle)">
          </p>
          <a  [href]="isService === false ? getProjectUrl(i18n.getOneProjects(slider.link)) :'' " [style]="!isService === false ? 'display : none' : ''" class="blue-circle gray-circle">
            <span> {{ i18n.getTextGeral("more") }}</span></a
          >
        </div>
        <h1  [style]="isService === false ? 'display : none' : ''" class="slider-title">{{slider.title}}</h1>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <p class="carousel-nav" *ngIf="hasMultipleImages()">
    <a class="btn-prev" (click)="owlCar.prev()"></a>
    <a class="btn-next" (click)="owlCar.next()"></a>
  </p>
  <!-- <div class="full-img-content">
    <img src="/assets/icon_fullscreen.png">
  </div> -->

  <div class="scroll-img-content">
    <img src="/assets/icon_scroll_down.png">
  </div>
  <p class="carousel-dots">
    <a
      *ngFor="let slider of sliders; index as i"
      class="btn btn-success"
      [class]="selectedIndexOwlCarousel === i ? 'active' : ''"
      (click)="owlCar.to(slider.slug)"
    ></a>
  </p>
</div>
