import { Component, OnInit } from '@angular/core';
import {Project} from "../../classes/project";
import { I18nService } from '../../services/translations/i18n.service';

@Component({
  selector: 'app-home-projects-block',
  templateUrl: './home-projects-block.component.html',
  styleUrls: ['./home-projects-block.component.scss']
})
export class HomeProjectsBlockComponent implements OnInit {
  public projectos: Project[];

  constructor(public i18n: I18nService) {

    this.projectos = i18n.getProjects();

  }

  ngOnInit(): void {
  }

}
