import { Component, OnInit, Input } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Silder } from 'src/app/classes/silder';
import { Project } from 'src/app/classes/project';

@Component({
  selector: 'app-home-screen-splash',
  templateUrl: './home-screen-splash.component.html',
  styleUrls: ['./home-screen-splash.component.scss'],
})
export class HomeScreenSplashComponent implements OnInit {
  @Input() sliders: Array<Silder> ;

  @Input() isService: any ;

  public selectedIndexOwlCarousel = 0;
  public customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    dots: false,
    // responsiveClass: true,
    //navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };


  constructor(public i18n: I18nService) {

  }

  public getBackgroundImageSlider(slider: Silder) {
    return (
      'background-image: url(' + slider.imagemDestaque + ')'
    );
  }
  public removeHTml( text){
    return text.replace(/<.*?>/g, '');
  }
  public getProjectUrl(project : Project){

    return ('/services/'+ project.slug );


  }

  public getPassedData($event) {
    this.selectedIndexOwlCarousel = $event.startPosition;
  }
  public hasMultipleImages(){

    return this.sliders.length === 1 ? false : true;
  }

  ngOnInit(): void {}

}
