<div class="home-contacts" id="home-contacts" style="min-height: 300px">
  <a class="blue-circle small"> </a>

  <div class="contacts-info-content">
    <ul>
      <li [innerHTML]="i18n.getTextContacts('adress')"></li>
    </ul>
    <ul>
      <li
        *ngFor="
          let email of i18n.getTextContacts('email');
          index as i;
          trackBy: trackByFn
        "
      >
        {{ email }}
      </li>
    </ul>
    <ul>
      <li
        *ngFor="
          let phone of i18n.getTextContacts('phone');
          index as i;
          trackBy: trackByFn
        "
      >
        {{ phone }}
      </li>
    </ul>
  </div>
  <a
    target="_blank"
    class="map-img"
    [title]="i18n.getTextGeral('openMaps')"
    [href]="i18n.getSocialMedia('googlemaps')"
  >
  </a>
</div>
