<div [class]=" isService === true ? 'white' : 'gray'" class="second-block-content">
  <img  *ngIf="isService === false" class="img-fluid second-block-img" src="/assets/home_who_1.jpg" />
  <div *ngIf="isService === true"  class="back-button-content">
    <a class="back-project-link" href="/" > <img src="/assets/icon_previous_dark.png"> {{i18n.getTextGeral('backProject')}}</a>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <h1 class="title">{{contentInformation.title}}</h1>
      <h1 title="subtitle">
        {{contentInformation.subtitle}}
      </h1>
    </div>
    <div class="col-12 col-md-6">
      <div [innerHTML]="contentInformation.description"></div>
      <a *ngIf="isService === false" [href]="contentInformation.link" class="blue-circle gray-circle" >
        <span> {{ i18n.getTextGeral("more") }}</span>
      </a
      >
    </div>
  </div>
</div>
