import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
// I import Location so that I can query the current path
import { Location } from "@angular/common";
// I also import Router so that I can subscribe to events
import { Router, NavigationEnd } from "@angular/router";
import { I18nService } from '../../services/translations/i18n.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public srcLogoImage: String = '/assets/wse_logo_header_white.png';
  public openMenu: Boolean = false;
  private hasheaderMenu: Boolean = false;

  constructor(
    location: Location, router: Router,
    public i18n: I18nService) {


    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // Navigation Ended Successfully.
         console.log(val.url);
         if(
           val.url === "/services" ||
           val.url === "/privacy-cookie" ||
          val.url === "/privacy-policy" ||
          val.url.includes("/about-us")
          ){
          this.hasheaderMenu = true;
          let element = document.getElementById('navbar');
          element.classList.add('sticky');
          this.srcLogoImage = '/assets/wse_logo_header_gray.png';
        }else{
          this.hasheaderMenu = false;
          let element = document.getElementById('navbar');
        element.classList.remove('sticky');
        this.srcLogoImage = '/assets/wse_logo_header_white.png';
        }

        console.log("mudei re dota nav bar " + this.hasheaderMenu);


    }
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 60) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');

      this.srcLogoImage = '/assets/wse_logo_header_gray.png';
    } else {
      if (!this.hasheaderMenu){
        let element = document.getElementById('navbar');
        element.classList.remove('sticky');
        this.srcLogoImage = '/assets/wse_logo_header_white.png';
      }

    }
  }
  toggleMenuClick() {
    this.openMenu = !this.openMenu;
  }

  ngOnInit(): void {

  }

  ngOnOnDestroy(): void {


  }
}
