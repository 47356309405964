import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';
@Component({
  selector: 'app-home-contacts',
  templateUrl: './home-contacts.component.html',
  styleUrls: ['./home-contacts.component.scss'],
})
export class HomeContactsComponent implements OnInit {
  constructor(public i18n: I18nService) {}

  public trackByFn(index, item) {
    return index;
  }

  ngOnInit(): void {}
}
