import { Component, OnInit, Input } from '@angular/core';
import { I18nService } from '../../services/translations/i18n.service';
import { Project } from '../../classes/project';

declare var $: any;

@Component({
  selector: 'app-home-project-card',
  templateUrl: './home-project-card.component.html',
  styleUrls: ['./home-project-card.component.scss'],
})
export class HomeProjectCardComponent implements OnInit {
  @Input() project: Project;
  @Input() isHomePage: Boolean;

  constructor(public i18n: I18nService) {}

  public getUrlImagemDestaque() {
    return this.project === null
      ? ''
      : 'assets/service/' +
          this.project.slug +
          '/' +
          this.i18n.getLanguage() +
          '/' +
          this.project.imagemDestaque;
  }

  ngOnInit(): void {}
}
