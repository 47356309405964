import { Component, OnInit } from '@angular/core';
import { Router  } from "@angular/router";
import { I18nService } from '../../services/translations/i18n.service';

@Component({
  selector: 'app-privacy-cookie',
  templateUrl: './privacy-cookie.component.html',
  styleUrls: ['./privacy-cookie.component.scss']
})
export class PrivacyCookieComponent implements OnInit {

  private subscribeLang = null;
  public data : any;

  constructor(
    private router: Router,
    public i18n: I18nService) 
    { 

    if(this.router.url === "/privacy-cookie"){
      this.data =    i18n.getTextPolicy("cookies");
    }else{
      this.data =    i18n.getTextPolicy("data");
    }

    this.subscribeLang = this.i18n.checkChangeLanguage().subscribe((data) => {
      if(location.pathname === "/privacy-cookie"){
        this.data =    i18n.getTextPolicy("cookies");
      }else{
        this.data =    i18n.getTextPolicy("data");
      }
      console.log('i18n checkChangeLanguage  subscribe !!!');
    });

 
    
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscribeLang.unsubscribe();
  }

}
